import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import axios from "axios";
import { API_URL } from "../../config";
import { useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";

const YourEvents = () => {
    const { event_id } = useParams();
    const [eventDetail, setEventDetail] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [ticket_hosted, setTicket_hosted] = useState(0);
    const [ticket_sold, setTicket_sold] = useState(0);
    const [Revenue, setRevenue] = useState(0);

    const getEventDetails = async () => {
        try {
            const id = event_id;
            const { data, status } = await axios.get(`${API_URL}/api/eventdetail/${id}`);

            setEventDetail(data); // This updates the event detail state

            console.log("EVENTDETAILS : ", data); // Use 'data' instead of 'eventDetail'

            if (status === 200 && data.length > 0) {
                let total_ticket = 0;
                let ticket_sell = 0;
                let total_revenue = 0;

                for (let i = 0; i < data[0].ticket.length; i++) {
                    total_ticket += data[0].ticket[i].total_Quantity;
                    ticket_sell += (data[0].ticket[i].total_Quantity - data[0].ticket[i].ticket_avability);
                }

                // Update state
                setTicket_hosted(total_ticket);
                setTicket_sold(ticket_sell);

                const soldData = await axios.get(`${API_URL}/api/booking/${id}`);
                if (soldData.status === 200) {
                    for (let k = 0; k < soldData.data.length; k++) {
                        total_revenue += soldData.data[k].paid_Amount;
                    }
                    setRevenue(total_revenue);
                }
            }
        } catch (error) {
            console.error('Failed to fetch event:', error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getEventDetails();
    }, [event_id]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10">
                        <div>
                            {isLoading ? (
                                <div className='d-flex justify-content-center align-items-center my-5' style={{ height: "100vh" }} >
                                    <PuffLoader size={48}
                                        loading={isLoading} />
                                </div>
                            ) : (
                                <div className="row mb-5">
                                    <p className="vender-heading">{eventDetail.event_name}</p>
                                    <div className='col-lg-3 col-md-3'>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className='dashboard-summary fw-semibold'>Total Tickets Hosted</p>
                                                        <p>{ticket_hosted}</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div>
                                                    <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.8% </span>
                                                        <span>Up from year</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3'>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className='dashboard-summary fw-semibold'>Total Tickets Sold</p>
                                                        <p>{ticket_sold ? ticket_sold : "0"}</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div>
                                                    <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.3% </span>
                                                        <span>Up from past week</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3'>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className='dashboard-summary fw-semibold'>Total Revenue</p>
                                                        <p>{Revenue ? Revenue : "0"}</p>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div>
                                                    <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 4.3% </span>
                                                        <span>Down from yesterday</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default YourEvents