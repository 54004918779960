import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { API_URL } from "../../config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const DashBoard = () => {
    const vendor = JSON.parse(localStorage.getItem('userData'));
    const [dashBoard_Values, SetDashBoard_Values] = useState({})

    const getDashBoardData = async () => {
        try {
            const response = await axios.get(`${API_URL}/vendor/dashboard/${vendor._id || vendor.uid}`)
            if (response.status === 200) {
                SetDashBoard_Values(response.data)
            }
        } catch (error) {

        }
    }
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    let ticket_Sold = 0;

    const getEventList = async () => {
        try {
            const result = await axios.get(`${API_URL}/vendor/events/${vendor._id || vendor.uid}`);
            setEvents(result.data);
        } catch (error) {
            alert("Something went wrong");
        }
    };

    const totalticket = (ticket) => {
        let totaltickets = 0;
        for (let i = 0; i < ticket.length; i++) {
            totaltickets += ticket[i].total_Quantity;
            ticket_Sold +=(ticket[i].total_Quantity - ticket[i].ticket_avability);
        }
        return totaltickets.toLocaleString();
    };

    // const formatTime = (timeString) => {
    //     if (!timeString) {
    //         timeString = "00:00:00";
    //     }

    //     const dateObj = new Date(`1970-01-01T${timeString}Z`);
    //     let hours = dateObj.getUTCHours();
    //     const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    //     const ampm = hours >= 12 ? 'PM' : 'AM';
    //     hours = hours % 12 || 12;

    //     return `${hours}:${minutes} ${ampm}`;
    // };

    const handleShowEventDetails = (event_id)=>{
        navigate(`/vendor/event-detail/${event_id}`)
    }

    useEffect(() => {
        getDashBoardData();
        getEventList();
    }, [dashBoard_Values])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 col-8 px-2">
                        <div>
                            <div className="row mb-5">
                                <p className="vender-heading">Your Dashboard</p>
                                <div className='col-lg-3 col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className='dashboard-summary fw-semibold'>Total Events Hosted</p>
                                                    <p>{dashBoard_Values.event_hosted ? dashBoard_Values.event_hosted : "0"}</p>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div>
                                                <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 8.5% </span>
                                                    <span>Up from last year</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className='dashboard-summary fw-semibold'>Total Tickets Sold</p>
                                                    <p>{dashBoard_Values.ticket_sold ? dashBoard_Values.ticket_sold : "0"}</p>
                                                </div>
                                                <div></div>
                                            </div>
                                            <div>
                                                <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.3% </span>
                                                    <span>Up from past week</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className='dashboard-summary fw-semibold'>Total Tickets Hosted</p>
                                                    <p>{dashBoard_Values.ticket_hosted ? dashBoard_Values.ticket_hosted : "0"}</p>
                                                </div>
                                                <div></div>
                                            </div>
                                            <div>
                                                <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.8% </span>
                                                    <span>Up from year</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className='dashboard-summary fw-semibold'>Total Revenue</p>
                                                    <p>{dashBoard_Values.ticket_revenue ? dashBoard_Values.ticket_revenue : "0"}</p>
                                                </div>
                                                <div></div>
                                            </div>
                                            <div>
                                                <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 4.3% </span>
                                                    <span>Down from yesterday</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                <p className="vender-heading">Events Details </p>
                                    <table className="table table-hover">
                                        <thead className="tableHead">
                                            <tr>
                                                <th scope="col">Event Name</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Tickets Hosted</th>
                                                <th scope="col">Tickets Sold</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {events.length > 0 ? (
                                                events.map((event) => {
                                                    return (
                                                        <tr className="tableDataRow" key={event._id} onClick={()=>handleShowEventDetails(event._id)}>
                                                            <td>{event.event_name}</td>
                                                            <td>{event.event_city}</td>
                                                            <td>{new Date(event.event_starts_date).toLocaleDateString('en-GB').replace(/\//g, '-')}</td>
                                                            <td>{totalticket(event.ticket)}</td>
                                                            <td>{ticket_Sold}</td>
                                                            <td><span className="vendor-event-status-active">{
                                                            (event.event_ends_date < new Date())? "Completed" : "Active"
                                                            }</span></td>
                                                        </tr>
                                                    )
                                                })
                                            ) : <p>.........No Event Added........</p>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashBoard