import './App.css';
import { Route, Routes } from 'react-router-dom';
import AddNewEvent from './Vendor/Pages/AddNewEvent';
import LoginPage from './Vendor/Pages/LoginPage';
import AccountSetUp from './Vendor/Pages/AccountSetUp';
import DashBoard from './Vendor/Pages/DashBoard';
import ManageCampaign from './Vendor/Pages/ManageCampaign';
import TicketScan from './Vendor/Pages/TicketScan';
import YourEvents from './Vendor/Pages/YourEvents';
import CreateEvent from './Vendor/Pages/CreateEvent';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<AccountSetUp />} />
        {/* Vendor Routes */}
        <Route path="/details" element={<AddNewEvent />} />
        <Route path="/vendor/dashboard" element={<DashBoard />} />
        <Route path="/vendor/yourevents" element={<YourEvents />} />
        <Route path="/vendor/ticketscan" element={<TicketScan />} />
        <Route path="/vendor/create-event" element={<CreateEvent />} />
        <Route path="/vendor/campaigns" element={<ManageCampaign />} />
        <Route path="/vendor/event-detail/:event_id" element={<YourEvents />} />
      </Routes>
    </div>
  );
}

export default App;
