import React from "react";
import './VendorSideBar.css'
import { TbArrowLeftFromArc } from "react-icons/tb";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PiGridFour } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { VscChecklist } from "react-icons/vsc";
import { AiOutlineDashboard } from "react-icons/ai";

const VendorSideBar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // to get the current location

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    // Helper function to check if a route is active
    const isActive = (path) => location.pathname === path;

    return (
        <div className="col-lg-2 col-md-2 col-4 border-end" style={{ height: "100vh" }}>
            <div className={`sidebar-list ${isActive('/vendor/dashboard') ? 'active' : ''}`}>
                <Link className='sidebar-link' to='/vendor/dashboard'>
                    <AiOutlineDashboard className='sidebar-icon' />
                    <span className='sidebar-tab'>Dashboard</span>
                </Link>
            </div>
            <div className={`sidebar-list ${isActive('/vendor/yourevents') ? 'active' : ''}`}>
                <Link className='sidebar-link' to='/vendor/yourevents'>
                    <PiGridFour className='sidebar-icon' />
                    <span className='sidebar-tab'>Your Events</span>
                </Link>
            </div>
            <div className={`sidebar-list ${isActive('/vendor/ticketscan') ? 'active' : ''}`}>
                <Link className='sidebar-link' to='/vendor/ticketscan'>
                    <FaRegHeart className='sidebar-icon' />
                    <span className='sidebar-tab'>Tickets Scanner</span>
                </Link>
            </div>
            <div className={`sidebar-list ${isActive('/vendor/create-event') ? 'active' : ''}`}>
                <Link className='sidebar-link' to='/vendor/create-event'>
                    <MdOutlineMessage className='sidebar-icon' />
                    <span className='sidebar-tab'>Create Event</span>
                </Link>
            </div>
            <div className={`sidebar-list ${isActive('/vendor/campaigns') ? 'active' : ''}`}>
                <Link className='sidebar-link' to='/vendor/campaigns'>
                    <VscChecklist className='sidebar-icon' />
                    <span className='sidebar-tab'>Campaigns</span>
                </Link>
            </div>
            <div className='sidebar-list'>
                <Link className='sidebar-link' to='/' onClick={handleLogout}>
                    <TbArrowLeftFromArc className='sidebar-icon' />
                    <span className='sidebar-tab'>Logout</span>
                </Link>
            </div>
        </div>
    );
}

export default VendorSideBar;
