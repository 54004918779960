import React from 'react'
import './NavigationBar.css'
import Logo from '../../Assets/logo_197x53-01.png'
import { TbUser } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { BsList } from 'react-icons/bs';

function NavigationBar() {
    const user = JSON.parse(localStorage.getItem('userData'));
    return (
        <>
            <div className='nav d-flex justify-content-between align-items-center' id="largesScreenDevice">
                <div className='logoforVender'>
                    <Link to="/vendor/dashboard">
                        <img className='w-100 h-100' src={Logo} alt="Logo" />
                    </Link>
                </div>
                <div className='me-5'>
                    <Link className="link-light  link-underline link-underline-opacity-0 userinfo fw-semibold" to="/vendor/dashboard"><span><TbUser className='mb-2' size={25} /></span> <span className='fs-5'>{user ? user?.name : "Guest"}</span></Link>
                </div>
            </div>

            {/* Navbar for smallScreenDevice */}
            <div className='nav' id='SmallScreenDevice'>
                <div className='navbar'>
                    <div className="dropdown togglebtn">
                        <BsList className='dropdown-toggle fs-1 ms-2 fw-bold'
                            data-bs-toggle="dropdown"
                            aria-expanded="false" aria-controls="responsiveNavbar"
                        />
                        {/* <ul className="dropdown-menu collapseNavbar">
                            <div className='d-flex justify-content-end pe-2'>
                                <FiX className='text-dark' size={30} />
                            </div>
                            <li >
                                <div className='userProfile'>
                                    {
                                        userLoggedIn ? (userLoggedIn.gender === "female" ? <img className='userProfilePic' src={femaleProfilePic} alt='profilePic'></img>
                                            : <img className='userProfilePic' src={maleProfilePic} alt='profilePic' />
                                        ) : ''
                                    }
                                    <h5 className='fw-bold'> Hi, {userLoggedIn ? userLoggedIn[0]?.firstName : "Guest"}</h5>
                                </div>
                            </li>
                            <li>
                                <div className='sidenavcontent'>
                                    <ul>
                                        <li>
                                            <Link className='link' onClick={locationModal}>
                                                <AiOutlineEnvironment className='text-dark' />
                                                {userLocation ?
                                                    <span className='ps-1 text-dark fw-semibold'> {userLocation}</span>
                                                    : <span className='ps-1 text-dark fw-semibold'> Your City</span>
                                                }
                                            </Link>
                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link to='/list-your-event' className='link'
                                                onClick={handleListUrEvent}>
                                                <TbSitemap className='text-dark' />
                                                <span className='ps-1 text-dark fw-semibold'> List Your Event </span>
                                            </Link >
                                        </li>
                                        {
                                            userLoggedIn ? <>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/userprofile" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <AiOutlineUser /> <span>Profile</span>
                                                    </Link>
                                                </li>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/yourtickets" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <BsTicketDetailed /> <span>Your Ticket</span>
                                                    </Link>
                                                </li>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/hosted-events" className='link-dark link-underline-opacity-0 fw-semibold '>
                                                        <BiFile /> <span>Event Hosted</span>
                                                    </Link>
                                                </li>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="#" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <BiFile /> <span>Reward Points</span>
                                                    </Link>
                                                </li>

                                            </> : ''
                                        }
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            {userLoggedIn ?
                                                <Link to='/' className='link' onClick={handleLogout}>
                                                    <TbArrowLeftFromArc className='text-dark' />
                                                    <span className='ps-1 text-dark fw-semibold'> Logout </span>
                                                </Link> :
                                                <Link to='/login' className='link' >
                                                    <TbArrowLeftToArc className='text-dark' />
                                                    <span className='ps-1 text-dark fw-semibold'> Login </span>
                                                </Link>

                                            }
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul> */}
                    </div>
                    <div className='logoforVender'>
                        <Link to="/profile">
                            <img className='w-100 h-100' src={Logo} alt="Logo" />
                        </Link>
                    </div>
                    <div className='searchbtn'>
                        <TbUser className="searchIcon" size={30} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationBar