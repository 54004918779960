import React, { useState } from 'react'
import { API_URL } from '../../config';
import axios from 'axios';
import apiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const Agreement = ({ vendorBasicDetail, vendorDocument }) => {
    const navigate = useNavigate();
    const [agreement, setAgreement] = useState();
    const [uploaded_Agreement, setUploaded_Agreement] = useState("");
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [isUploaded, setIsUploaded] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFileSelection = (event) => {
        setUploaded_Agreement(event.target.files[0].name);
        setAgreement(event.target.files[0]);
        setIsBtnDisabled(false); // Enable the PAN card upload button
    };

    const handleFileUpload = async () => {
        const fd = new FormData();
        fd.append('image', agreement);

        try {
            const response = await axios.post(`${API_URL}/vendor/document/uploadagreement`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                const uploadedFilename = response.data.file.filename;
                setAgreement(uploadedFilename);
                setIsUploaded(true); // Mark PAN card as uploaded
                setIsBtnDisabled(true); // Disable the PAN card upload button
                alert('Agreement uploaded successfully');
            }
        } catch (error) {
            alert('Error uploading PAN card');
        }
    };

    const validate = () => {
        const newErrors = {};

        if (!agreement) {
            newErrors.agreement = 'Signed Agreement is required';
        } else if (!isUploaded) {
            newErrors.uploadagreement = 'Please upload the Signed Agreement';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const isValid = validate();

        if (isValid) {
            try {
                const formData = {
                    vendorBasicDetail: vendorBasicDetail,
                    vendorDocumen: vendorDocument,
                    agreement: agreement
                }
                if (formData) {
                    const response = await axios.post(`${API_URL}/vendor/addvendor`, formData)
                    if (response.status === 200) {
                        apiService.setData(formData);
                        alert("Vendor registered successfull!")
                        navigate("/vendor/dashboard");
                    } else {
                        alert("Error! please try again")
                    }
                }
            } catch (error) {
                alert("Something went wrong please try again")
            }
        }
        
    }

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <div id="basics-detail">
                    <div className='addeventdetailDiv'>
                        <div className='container'>
                            <div className='container'>
                                <div className='mt-lg-5 mt-md-5'>
                                    <div className='text-center'>
                                        <span className='poppins30 fw-semibold'>Sign Agreement</span>
                                    </div>
                                    <div className='container'>
                                        <div className='container d-flex justify-content-center'>
                                            <div>
                                                <div className='mt-3'>
                                                    <div className="text-center mb-3">
                                                        <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='agreement'>Upload Signed Agreement</label>
                                                        <div className='coverImg_style m-auto mb-3'>
                                                            <div className='uploadFile text-center'>
                                                                <AiOutlineCloudUpload className='uploadIcon' />
                                                                {agreement ? (
                                                                    <div>
                                                                        <span className='poppins16 fw-bold'>Uploaded: </span> <p>{uploaded_Agreement}</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className='text-center'>
                                                                        <p className='poppins16'>Drag and Drop files or <a href="#">Browse</a></p>
                                                                        <p className='poppins12'>Supported format: jpeg, pdf</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection} />
                                                        </div>
                                                        {errors.agreement && <p className="text-danger">Signed Agreement is required</p>}
                                                        {errors.uploadagreement && <p className="text-danger">Please upload the selected Signed Agreement</p>}
                                                        <button type='button' className="btn uploadbtn" onClick={handleFileUpload} disabled={isBtnDisabled}>Upload Signed Agreement</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-end'><span>We are here to help sales@myfrontseat.in</span></div>
                <div className='adminfooterDiv'>
                    <button type='submit' className="btn btn-primary basicnextbtn">Save</button>
                </div>
            </form>
        </>
    )
}

export default Agreement
