import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import axios from "axios";
import { API_URL } from "../../config";

const TicketScan = () => {
    const [events, setEvents] = useState([]);
    const user = JSON.parse(localStorage.getItem('userData'));
    const [eventName, setEventName] = useState("");
    const [generatedLink, SetGeneratedLink] = useState({});

    const getEventList = async () => {
        try {
            const result = await axios.get(`${API_URL}/vendor/events/${user._id}`);
            setEvents(result.data);
        } catch (error) {
            alert("Something went wrong");
        }
    };

    const handleGeneratelink = async (e) => {
        e.preventDefault();
        console.log("handleGeneratelink called : ", eventName)
        const body = {
            loginId: user.name,
            event_id: eventName
        }
        const response = await axios.post(`${API_URL}/vendor/generate-link`, body)
        if (response.status === 200) {
            const link = response.data;
            alert("Link generated successfully");
            SetGeneratedLink(link)
            console.log("generatedLink :", generatedLink)
        }
    }

    useEffect(() => {
        getEventList();
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10">
                        <div className='lists'>
                            <div className='d-flex justify-content-between border-bottom border-primary'>
                                <span className='profileheading fw-semibold'>Ticket Scanner</span>
                            </div>
                            <div className='ListofEvents mt-3 w-50'>
                                <label className='listheading fw-semibold'>Select Event</label>
                                <select className='form-control border-primary'
                                    name='eventName'
                                    placeholder='Enter Event Category'
                                    value={eventName}
                                    onChange={(e) => setEventName(e.target.value)}>
                                    <option value="" disabled>Select Event</option>
                                    {events.length > 0
                                        ? events.map((event) => (
                                            <option key={event._id} value={event._id}>
                                                {event.event_name}
                                            </option>
                                        ))
                                        : null}
                                </select>

                                <div className="mt-5">
                                    <button className="generate-btn" onClick={handleGeneratelink}>Generate Link</button>
                                </div>
                                {/* Render the generated link */}
                                {generatedLink && (
                                    <div className="mt-5">
                                        <div className="card">
                                            <div className="card-body">
                                                <p><b>Ticket Scanner Link:</b> https://www.hostyourfrontseat.in/event1/ticketscanner/{generatedLink.event_id}</p>
                                                <p><b>Login id:</b> {generatedLink.login_id}</p>
                                                <p><b>Password:</b> {generatedLink.password}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketScan