import React from 'react';
import VendorSideBar from '../Components/VendorSideBar';
import { Link } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';

const CreateEvent = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 d-flex justify-content-center align-items-center">
                        <div>
                            <Link to='/details' className='createnewbtn px-5 py-3'>
                                <span> <BiPlus size={15} /> Create Event</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default CreateEvent;